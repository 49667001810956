const isProd = process.env.NODE_ENV === "production";
import { sFechaPublicacion } from "@/api-services/fechaPublicacion";
export default {
  IsProd() {
    // return true;
    return isProd;
  },
  get Version() {
    //012345678901234567890123456789
    //2022-11-02T10:55:56.9836714-06:00
    var Mes = sFechaPublicacion.substring(5, 7);
    var Dia = sFechaPublicacion.substring(8, 10);
    var Horas = sFechaPublicacion.substring(11, 13);
    var Minutos = sFechaPublicacion.substring(14, 16);
    return `vf0.6.${Mes}${Dia}${Horas}${Minutos}`;
    return "v0.5.1101";
  },
  get AuthorityURL() {
    var lURL = localStorage.AuthorityURL;
    if (lURL) return lURL;

    switch (location.hostname.toLowerCase()) {
      case "go.eclock.com.mx":
      case "localhost":
      default:
        return "https://eClockAD.b2clogin.com/eClockAD.onmicrosoft.com/B2C_1_InicioSesion";
      case "td.eclock.com.mx":
      case "dolphin.eclock.com.mx":
      case "twindolphin.eclock.com.mx":
        return `https://eClockAD.b2clogin.com/eClockAD.onmicrosoft.com/B2C_1_TwinDolphin`;
    }
  },
  get AuthURL() {
    var lURL = localStorage.AuthURL;
    if (lURL) return lURL;

    switch (location.hostname.toLowerCase()) {
      case "localhost":
        return "http://localhost:8080";

      default:
        return "https://" + location.hostname;
      //return "http://localhost:8080/#/login?ad=1";
    }
  },
  get URL() {
    var lURL = localStorage.ApiURL;
    if (lURL) return lURL;

    switch (location.hostname.toLowerCase()) {
      case "localhost":
        return "http://localhost:8064/api";
      case "go.eclock.com.mx":
        return "https://apiv6go.eclock.com.mx/api";
      case "gob.eclock.com.mx":
        return "https://apiv6b.eclock.com.mx/api";
      case "god.eclock.com.mx":
        return "https://apiv6d.eclock.com.mx/api";
      case "twindolphin.eclock.com.mx":
      case "dolphin.eclock.com.mx":
      case "td.eclock.com.mx":
        return "https://apiv6td.eclock.com.mx/api";
      case "modofoto.eclock.com.mx":
        return "https://apiv6b.eclock.com.mx/api";
    }

    lURL = this.IsProd()
      ? "https://apiv6.eclock.com.mx/api"
      : "https://apiv6.eclock.com.mx/api"; // "https://debug.apiv6.eclock.com.mx/api")
    //  localStorage.URL = lURL;

    return lURL;
  },
  get SignalRURL() {
    var lURL = localStorage.SignalRURL;
    if (lURL) return lURL;
    lURL = "https://ServicesV6.eclock.com.mx/SignalRHub"; //((this.IsProd()) ? "https://apiv6.eclock.com.mx/api" : "https://debug.apiv6.eclock.com.mx/api")
    // localStorage.SignalRURL = lURL;

    return lURL;
  },
  /**
   * Es single sign on o no
   */
  get EsSSO() {
    return false;
    //    return localStorage.EsSSO;
  },
  set EsSSO(valor) {
    localStorage.EsSSO = valor;
  },
  GuardaValor(Nombre, Valor) {
    localStorage[Nombre] = Valor;
  },
  ObtenValorBool(Nombre, Predeterminado) {
    var Valor = localStorage[Nombre];
    if (Predeterminado == null || Predeterminado == undefined)
      Predeterminado = false;
    if (Valor == null || Valor == undefined) return Predeterminado;
    if (Valor == true || Valor == "true") return true;
    return false;
  },
  ObtenValor(Nombre, Predeterminado) {
    var Valor = localStorage[Nombre];
    if (Predeterminado == null || Predeterminado == undefined)
      Predeterminado = "";
    if (Valor == null || Valor == undefined) return Predeterminado;

    return Valor;
  },
  CabiarTema(Obscuro) {
    if (Obscuro == null || Obscuro == undefined) {
      if (localStorage.Obscuro == null || localStorage.Obscuro == undefined) {
        localStorage.Obscuro = true;
        Obscuro = true;
      } else Obscuro = localStorage.Obscuro;
    } else localStorage.Obscuro = Obscuro;
    let docClasses = document.body.classList;
    //console.debug(`Obscuro=${Obscuro}`);
    if (Obscuro == true || Obscuro == "true") {
      docClasses.remove("white-content");
      docClasses.add("maz-is-dark");
    } else {
      docClasses.add("white-content");
      docClasses.remove("maz-is-dark");
    }
  },
};
